import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectSkipInitial = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    effect();
  }, deps);
};
